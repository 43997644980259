/* External dependencies */
import { Col, Div, Icon, Row, Text } from 'atomize';
import { navigate } from 'gatsby-plugin-react-intl';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import DateTime from '../common/DateTime';
import { getUserRole, setLocalStorage } from '../common/helpers';
import { UserRoles } from '../common/roles';
import { Consignee } from '../create-cargo/redux/actions';
import Search from '../search/Search';
import Table from '../tables/Table';
import Tabs from '../tabs/Tabs';
import { Vehicle } from '../vehicle-details/redux/actions';
import {
  Invoice,
  listInvoices,
  selectInvoice,
  sortInvoicesBy,
} from './redux/actions';
import { adminTabs, cashierTabs } from './common/invoice-tabs';
import { GetInvoiceRequest } from './invoice/redux/actions';
import { InvoicesState } from './redux/reducer';

interface RouterProps {
  path?: string | string[];
}

export default function Invoices(path: RouterProps) {
  const findOrganization = formatMessage('findOrganization');

  const dispatch = useDispatch();

  const { currentPage, filter, invoices, loading, searchString, total } =
    useSelector((state: RootState): InvoicesState => state.invoices);

  const { currentUser } = useSelector((state: RootState) => state.login);

  useEffect(() => {
    dispatch(listInvoices());
  }, []);

  const currentTab = JSON.stringify(filter);

  function onFilterChange(e) {
    const filter = JSON.parse(e.target.value);

    dispatch(listInvoices('', 1, filter));
  }

  function onSearch(e) {
    dispatch(listInvoices(e.target.value, 1, filter));
  }

  function resetSearchField() {
    onSearch({
      target: { value: '' },
    });
  }

  function onPageChange(page) {
    dispatch(listInvoices(searchString, page, filter));
  }

  function onSelect(invoice: Invoice) {
    dispatch(selectInvoice(invoice));
  }

  function onInvoicesSort() {
    dispatch(sortInvoicesBy('registrationDate'));
  }

  function onShowPayment({ id }: GetInvoiceRequest) {
    setLocalStorage('invoiceId', id);

    navigate('/invoices/invoice');
  }

  function renderName(invoice) {
    return (
      <Div cursor="pointer" onClick={() => onShowPayment(invoice)}>
        {getInvoiceName(invoice)}
      </Div>
    );
  }

  function getInvoiceName(invoice: Invoice) {
    return invoice.consignee !== null
      ? invoice.consignee.name
      : invoice.vehicle.driver.name;
  }

  const topBar = (
    <Text
      textColor="table_cell_text_color"
      textSize={{ xs: 'mobileTitle', md: 'title' }}
      textWeight="700"
    >
      <FormattedMessage id="Invoices" /> (
      {loading ? <Icon m="0 .62rem" name="Loading3" size="20px" /> : total})
    </Text>
  );

  return (
    <Table
      currentPage={currentPage}
      columns={getFields()}
      controlBar={getControlBar()}
      className="table-invoices"
      emptyMessage={<FormattedMessage id="noOrganizations" />}
      items={invoices.map((invoice: Invoice) => {
        if (invoice.consignee !== null) {
          return {
            ...invoice.consignee,
            name: renderName(invoice),
          };
        }

        return {
          ...invoice.vehicle,
          name: renderName(invoice),
        };
      })}
      loading={loading}
      onPageChange={onPageChange}
      onSelect={onSelect}
      searchString={searchString}
      stickyCol={1}
      topBar={topBar}
      totalItemsCount={total}
    />
  );

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_ADMIN:
        return getAdminControlBar();
      case UserRoles.BTS_CASHIER:
        return getCashierControlBar();
      default:
        throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }

  function getAdminControlBar() {
    return (
      <Row
        m={{ y: '.5rem', x: '-.5rem' }}
        justify="space-between"
        align={{ xs: 'flex-start', md: 'space-between' }}
      >
        <Col size={{ xs: '12', md: '6' }}>
          {
            <Tabs
              inputName=""
              items={adminTabs}
              onClick={onFilterChange}
              value={currentTab}
            />
          }
        </Col>
        <Col
          size={{ xs: '12', md: '6' }}
          d={{ xs: 'block', md: 'flex' }}
          justify={{ md: 'flex-end' }}
          style={{ marginBottom: '1rem' }}
        >
          <Search
            onChange={onSearch}
            onClear={resetSearchField}
            placeholder={findOrganization}
            value={searchString}
          />
        </Col>
      </Row>
    );
  }

  function getCashierControlBar() {
    return (
      <Row
        m={{ y: '.5rem', x: '-.5rem' }}
        justify="space-between"
        align={{ xs: 'flex-start', md: 'space-between' }}
      >
        <Col size={{ xs: '12', md: '6' }}>
          <Tabs
            inputName=""
            items={cashierTabs}
            onClick={onFilterChange}
            value={currentTab}
          />
        </Col>
        <Col
          size={{ xs: '12', md: '6' }}
          d={{ xs: 'block', md: 'flex' }}
          justify={{ md: 'flex-end' }}
          style={{ marginBottom: '1rem' }}
        >
          <Search
            onChange={onSearch}
            onClear={resetSearchField}
            placeholder={findOrganization}
            value={searchString}
          />
        </Col>
      </Row>
    );
  }

  function getFields() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_ADMIN:
        return getAdminFields();
      case UserRoles.BTS_CASHIER:
        return getCashierFields();
      default:
        throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }

  function getAdminFields() {
    return [...getCashierFields()];
  }

  function getCashierFields() {
    return [
      {
        field: (data: Consignee | Vehicle) => (
          <DateTime date={data.arrivalDate} />
        ),
        title: <FormattedMessage id="arrivalDate" />,
      },
      {
        field: (data: Consignee | Vehicle) =>
          (data as Consignee).name || (data as Vehicle).driver.name,
        title: <FormattedMessage id="payer" />,
      },
      {
        field: (data: Consignee | Vehicle) => data.vehiclePlateNumber,
        title: <FormattedMessage id="Vehicle" />,
      },
    ];
  }
}
