/* Local dependencies */
import { VehicleStatus } from '../../create-vehicle/vehicles_types';
import { PaymentStatus } from '../invoice/common/invoice-types';
import { DEFAULT_INVOICES_FILTER } from '../redux/actions';

export const adminTabs = [
  {
    label: 'unpaid',
    value: JSON.stringify(DEFAULT_INVOICES_FILTER),
  },
  {
    label: 'paid',
    value: JSON.stringify({ paymentStatus: PaymentStatus.PAID }),
  },
  {
    label: 'allInvoices',
    value: JSON.stringify({}),
  },
  {
    label: 'archive',
    value: JSON.stringify({
      paymentStatus: PaymentStatus.PAID,
      vehicleStatus: VehicleStatus.DEPARTED,
    }),
  },
];

export const cashierTabs = [
  {
    label: 'unpaid',
    value: JSON.stringify(DEFAULT_INVOICES_FILTER),
  },
  {
    label: 'paid',
    value: JSON.stringify({ paymentStatus: PaymentStatus.PAID }),
  },
  {
    label: 'allInvoices',
    value: JSON.stringify({}),
  },
];
